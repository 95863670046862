import { MAIN_URL } from '@src/constants'
// ** Auth Endpoints
export default {
  loginEndpoint: `${MAIN_URL}/api/login`,
  signupEndpoint: `${MAIN_URL}/api/signup`,

  refreshTokenEndpoint: `${MAIN_URL}/api/refresh`,
  createNewPasswordEndpoint: `${MAIN_URL}/api/create-password-confirm`,
  forgotPasswordEndpoint: `${MAIN_URL}/api/reset-password`,
  ResetPasswordEndpoint: `${MAIN_URL}/api/reset-password-confirm`,
  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',

  // ** Calendar
  calendarEndPoint: `${MAIN_URL}/api`,
  etheraLocationsEndPoint: `${MAIN_URL}/api/e-location`,
  addAClientsServicesEndPoint: `${MAIN_URL}/api/123`,

  addAppointmentEndPoint: `${MAIN_URL}/api/appointment`,

  providerLocationsEndPoint: `${MAIN_URL}/api`,
  calendarValidateRoomsEndPoint: `${MAIN_URL}/api/locations`,
  deleteCalendarBookingEndPoint: `${MAIN_URL}/api/booking`,
  updateCalendarAppointmentEndPoint: `${MAIN_URL}/api/appointment`,
  updateCalendarBookingEndPoint: `${MAIN_URL}/api/booking`,
  registerRoomCostsEndPoint: `${MAIN_URL}/api/providers/tiers/my-tier`,
  registerCumulativeRoomsCostEndPoint: `${MAIN_URL}/api/cumulative-api`,
  //** Client */
  getClientEndPoint: `${MAIN_URL}/api/clients/`,
  getAllClientsEndPoint: `${MAIN_URL}/api/clients`,
  registerClientEndPoint: `${MAIN_URL}/api/clients`,
  updateClientEndPoint: `${MAIN_URL}/api/clients/`,
  getClientNotesEndPoint: `${MAIN_URL}/api/document`,
  getClientDetailsEndPoint: `${MAIN_URL}/api/client-details`, /// get all client details in csv file API
  uploadInsuranceCardImageEndPoint: `${MAIN_URL}/api/users/`,
  getClientServicesEndPoint: `${MAIN_URL}/api/client-service`,
  getClientAppointmentsEndPoint: `${MAIN_URL}/api/client-overview`,
  getAllClientBillingEndPoint: `${MAIN_URL}/api/client-invoices`,
  getClientBillingEndPoint: `${MAIN_URL}/api/client-invoices/`,
  chargeClientEndPoint: `${MAIN_URL}/api/client-invoice-auto-pay`,
  updateClientBillingEndPoint: `${MAIN_URL}/api/client-invoices/`,
  getAllClientDocumentsEndPoint: `${MAIN_URL}/api/client-documents`,
  retriveAllClientDocumentsEndPoint: `${MAIN_URL}/api/retrieve-client-documents`,
  addClientsToAppointment: `${MAIN_URL}/api/appointment`,
  deleteClientFromAppointment: `${MAIN_URL}/api/appointment`,
  deleteNoteFromClientAppointment: `${MAIN_URL}/api/appointment-notes`,
  addAttachmentToClientNote: `${MAIN_URL}/api/appointment-notes`,
  deleteNoteAttachment: `${MAIN_URL}/api/appointment-notes`,
  addNoteToAppointment: `${MAIN_URL}/api/appointment-notes`,
  updateNoteInAppointment: `${MAIN_URL}/api/appointment-notes`,
  getClientInvoiceEndPoint: `${MAIN_URL}/api/invoice-client/`,
  getMessageDetailsEndPoint: `${MAIN_URL}/api/messages`,
  registerClientDocumentsEndPoint: `${MAIN_URL}/api/client-documents`,
  getAllIntakeFormEndPoint: `${MAIN_URL}/api/document`,
  BillingInvoiceEndPoint: `${MAIN_URL}/api/client-invoices`,
  SuperBillingInvoiceEndPoint: `${MAIN_URL}/api/superbill`,
  exportClientDetailsEndpoint: `${MAIN_URL}/api/export-all-client-documents`,
  // ** Admin Notes
  getAllAdminNotes: `${MAIN_URL}/api`,
  getAdminNoteById: `${MAIN_URL}/api`,
  addAdminNote: `${MAIN_URL}/api/appointment-notes`,
  updateAdminNote: `${MAIN_URL}/api`,
  deleteAdminNote: `${MAIN_URL}/api/appointment-notes`,

  //** Setting /  Scheduling /  Locations */
  getLocationEndPoint: `${MAIN_URL}/api/provider-location/`,
  getLocationAllEndPoint: `${MAIN_URL}/api/provider-location`,
  registerLocationEndPoint: `${MAIN_URL}/api/provider-location`,
  updateLocationEndPoint: `${MAIN_URL}/api/provider-location/`,
  deleteLocationEndPoint: `${MAIN_URL}/api/provider-location/`,

  //** Setting /  Management /  My profile */
  providerImageUploadEndPoint: `${MAIN_URL}/api/users/`,
  getProviderProfileEndPoint: `${MAIN_URL}/api/providers/me`,
  updateProviderProfileEndPoint: `${MAIN_URL}/api/providers/`,

  //** Appointments
  updateAppointmentEndpoint: `${MAIN_URL}/api/appointment/`,
  getAllAppointmentsEndPoint: `${MAIN_URL}/api/appointment-tab`,
  getAppointmentByIdEndPoint: `${MAIN_URL}/api/appointment-tab`,

  //** Bookings
  addBookingEndpoint: `${MAIN_URL}/api/booking`,
  editBookingEndPoint: `${MAIN_URL}/api/booking`,
  validateRoomEndPoint: `${MAIN_URL}/api/locations`,
  getAllBookingsEndPoint: `${MAIN_URL}/api/provider/booking-list`,
  getBookingByIdEndPoint: `${MAIN_URL}/api/booking`,
  getOnlyAppointmentByIdEndPoint: `${MAIN_URL}/api/appointment`,
  getUpdateBookingByIdEndPoint: `${MAIN_URL}/api/booking`,
  getBookingInvoiceEndPoint: `${MAIN_URL}/api/appointment-invoice`,

  //** Bookings / Billing invoices
  getAllBillingsEndPoint: `${MAIN_URL}/api/invoices`,
  getMonthlyInvoiceEndPoint: `${MAIN_URL}/api/invoices`,
  // Activities
  getAllActivitiesEndPoint: `${MAIN_URL}/api/activity-api`,
  // transactionHistory
  getAllTransactionHistoryEndPoint: `${MAIN_URL}/api/clients-transactions`,
  //** Locations */
  getAllLocationsEndPoint: `${MAIN_URL}/api/locations`,

  //**  Provider */
  getProviderDetailsEndPoint: `${MAIN_URL}/api/providers/`,
  update2faEndpoint: `${MAIN_URL}/api/two-fa-setting`,

  //** Reports */
  reportsEndPoint: `${MAIN_URL}/api/reports`,
  getAllReportsEndPoint: `${MAIN_URL}/api/reports`,
  getAllMonthlyClientsDetailsEndPoint: `${MAIN_URL}/api/clients/client-detail`,
  getAllSessionAttendanceEndPoint: `${MAIN_URL}/api/attendance`,
  getClientPaymentEndPoint: `${MAIN_URL}/api/client-payment`,

  //**  Notification  */
  getAllNotificationsEndPoint: `${MAIN_URL}/api/notification`,
  getNotificationsEndPoint: `${MAIN_URL}/api/notification-setting/me`,
  getReminderEndPoint: `${MAIN_URL}/api/reminder-setting`,

  //** Setting /  Billing /  Service */
  // getAllServiceEndPoint: `${MAIN_URL}/api/providers/`,
  getAllServiceEndPoint: `${MAIN_URL}/api/provider-service`,
  getServiceByIdEndPoint: `${MAIN_URL}/api/provider-service/`,
  updateServiceEndPoint: `${MAIN_URL}/api/provider-service/`,
  deleteServiceEndPoint: `${MAIN_URL}/api/provider-service/`,
  registerServiceEndPoint: `${MAIN_URL}/api/provider-service`,

  //** Setting /  Billing /  Billing Info */
  registerBillingInfoEndPoint: `${MAIN_URL}/api/billing-info`,

  //** Stripe */
  getAllStripeCardEndPoint: `${MAIN_URL}/api/provider-stripe`,
  registerStripeCardEndPoint: `${MAIN_URL}/api/provider-stripe-accounts`,
  registerMoreStripeCardEndPoint: `${MAIN_URL}/api/card`,
  updateStripeCardEndPoint: `${MAIN_URL}/api/provider-stripe/change-default`,
  // ConnectStripeAccountEndPoint: `${MAIN_URL}/api/connect-account`,
  ConnectStripeAccountEndPoint: `${MAIN_URL}/api/connect-stripe-account`,
  getProviderStripeStatus: `${MAIN_URL}/api/provider-stripe-status`,
  // ** Settings / Documents / Notes and Forms
  notesAndFormsEndPoint: `${MAIN_URL}/api/document`,

  // ** Settings / Documents /Other-Document
  OtherDocumentsEndPoint: `${MAIN_URL}/api/other-documents`,

  // ** Settings / Documents / Invoice
  getInvoiceEndPoint: `${MAIN_URL}/api/invoice-setting`,
  getSuperBillsEndPoint: `${MAIN_URL}/api/superbill-setting`,

  //** Client / Client Billing / Invoice */
  createClientInvoiceEndpoint: `${MAIN_URL}/api/client-invoices`,

  //** Setting /  Sccheduling /  Location */
  billingAddressEndpoint: `${MAIN_URL}/api/providers/`,

  //** Setting /  Sccheduling /  calendar setting */
  getProviderCalendarEndPoint: `${MAIN_URL}/api/provider-calendar/me`,
  getAppointmentEmailTemplateEndPoint: `${MAIN_URL}/api/appointment-setting`,
  updateAppointmentEmailTemplateEndPoint: `${MAIN_URL}/api/appointment-setting`,
  //** Setting /  Sccheduling /  Notification */
  reminderEndPoint: `${MAIN_URL}/api/reminder-setting/me`,
  updateReminderEndPoint: `${MAIN_URL}/api/reminder-setting/me`,
  markAllNotificationEndPoint: `${MAIN_URL}/api/notification/mark-all`,

  //** Messages */
  sendMessageEndpoint: `${MAIN_URL}/api/messages`,
  getAllConversationsEndpoint: `${MAIN_URL}/api/conversations`,

  //** TeleHealth */
  generateMeetingLinksEndPoint: `${MAIN_URL}/api/provider-telehealth`,
  getTelehealthEmailTemplateEndPoint: `${MAIN_URL}/api/telehealth-setting`,
  updateTelehealthTemplateEndPoint: `${MAIN_URL}/api/telehealth-setting`,
  // Chat File
  sendChatFileEndPoint: `${MAIN_URL}/api/messages`,
  getCurrentTimeEndPoint: `${MAIN_URL}/api/get-current-time`,
  getDiagnosisListEndPoint: `${MAIN_URL}/api/get-diagnosis`,
  addDiagnosisToClientEndPoint: `${MAIN_URL}/api/client-diagnosis`,
  getAllDiagnosisEndPoint: `${MAIN_URL}/api/get-client-diagnosis`,
  deleteClientDiagnosisEndPoint: `${MAIN_URL}/api/client-diagnosis`,
  getClientTreatmentEndPoint: `${MAIN_URL}/api/get-client-treatments`,
  addClientTreatmentEndPoint: `${MAIN_URL}/api/client-treatment`,
  editClientTreatmentEndPoint: `${MAIN_URL}/api/client-treatment`,
  deleteClientTreatmentEndPoint: `${MAIN_URL}/api/client-treatment`,
  send2FaAuthCodeEndpoint: `${MAIN_URL}/api/validate-otp`,
  resend2FaAuthCodeEndpoint: `${MAIN_URL}/api/resend-otp`,
  exportAllClientsDataEndpoint: `${MAIN_URL}/api/export-all-client-documents`,
  getAllUnbilledInvoicesEndPoint: `${MAIN_URL}/api/get-un-billed-invoices`,
  createInsuranceClaimEndPoint: `${MAIN_URL}/api/create-claim`,
  getAllInsuranceClaimsEndPoint: `${MAIN_URL}/api/get-claims`,
  retriveInsuranceClaimEndPoint: `${MAIN_URL}/api/get-claims`,
  getValidationRulesForClaimEndPoint: `${MAIN_URL}/api/configuration`,
  submitInsuranceClaimEndPoint: `${MAIN_URL}/api/submit-claim`,
  getProviderPayersEndPoint: `${MAIN_URL}/api/get_payers_member`,
  getPayersEndPoint: `${MAIN_URL}/api/get-payers-list`,
  addProviderPayerEndPoint: `${MAIN_URL}/api/payers`,
  deleteProviderPayerEndPoint: `${MAIN_URL}/api/payers`
}
